'use strict';

//var canvas = document.getElementById('canvas'),
//    header = document.getElementById('header'),
//    t = 0,
//    w, h;
//
//function resize() {
//    w = canvas.width = header.offsetWidth;
//    h = canvas.height = header.offsetHeight;
//}
//
//function animate() {
//    context.clearRect(0, 0, w, h);
//
//    t += 0.1;
//
//    for (var i = 0; i < 5000; i++) {
//        var f = 0.05 + ( ( Math.sin(t * 0.00002) / Math.PI ) * 0.2 );
//        var r = ( Math.min(w, h) ) * ( Math.cos((t + i) * f) / Math.PI * 1.5 );
//
//        var x = Math.sin(i) * r + (canvas.width / 2);
//        var y = Math.cos(i) * r + (canvas.height / 2);
//
//        context.fillStyle = 'rgba(25, 107, 172, 0.4)';
//        context.fillRect(x, y, 1.6, 1.6);
//    }
//
//    window.requestTimeout(animate, 32);
//
//}
//
//if (canvas) {
//
//    var context = canvas.getContext('2d');
//    context.globalCompositeOperation = 'lighter';
//
//    window.requestTimeout = function (fn, delay) {
//        if (!window.requestAnimationFrame && !window.webkitRequestAnimationFrame && !(window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame) && // Firefox 5 ships without cancel support
//            !window.oRequestAnimationFrame && !window.msRequestAnimationFrame)
//            return window.setTimeout(fn, delay);
//
//        var start = new Date().getTime(),
//            handle = {};
//
//        function loop() {
//            var current = new Date().getTime(),
//                delta = current - start;
//
//            delta >= delay ? fn.call() : handle.value = window.requestAnimationFrame(loop);
//        }
//
//        handle.value = window.requestAnimationFrame(loop);
//        return handle;
//    };
//
//    window.addEventListener('resize', resize, false);
//}


var scrollToTop = (function ($, window, document) {

    var settings = {
        element: '#scroll-to-top',
        setScrollPosition: 50,
        debug: false
    };

    var elementsRegister = {
        $document: $(document),
        $window: $(window)
    };

    var init = function ($options) {

        $options = $options || {};

        $.extend(true, settings, $options);

        var dHeight = elementsRegister.$document.height();
        var scrollSetPosition = dHeight * 50 / 100;
        var scrollTrigger = $(settings.element);

        // Hide on default if not set display none
        scrollTrigger.hide();

        function checkPosition() {

            if (settings.debug) {
                console.log(dHeight, elementsRegister.$window.scrollTop(), scrollSetPosition);
            }

            if (elementsRegister.$window.scrollTop() > scrollSetPosition) {
                scrollTrigger.fadeIn();
            } else {
                scrollTrigger.fadeOut();
            }
        }

        checkPosition();

        elementsRegister.$window.scroll(function () {
            checkPosition();
        });

        scrollTrigger.click(function (e) {
            e.preventDefault();
            $('body,html').animate({
                scrollTop: 0
            }, 800);

        });
    };

    return {
        init: init
    };

})(jQuery, window, document);

$(window).on('load', function () {

    //if (canvas) {
    //    resize();
    //    animate();
    //}

    $('img[data-original]').lazyload({
        effect: 'fadeIn'
    });
});

$(document).ready(function () {


    $('#achievementSlider').slick({
        dots: true,
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev achievement-slider__prev"><i class="fa fa-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next achievement-slider__next"><i class="fa fa-arrow-right"></i></button>',
        dotsClass: 'achievement-slider__dot slick-dots'
    });

    $('#clientList').slick({

        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 3500,
        dots: true,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev slider__prev"><i class="fa fa-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next slider__next"><i class="fa fa-arrow-right"></i></button>',
        dotsClass: 'slider__dot slick-dots',

        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('#mainNav').headroom();

    $('a').smoothScroll();

    $('.header__nav-mobile').on('click', function (e) {

        e.preventDefault();
        $('.header__nav').addClass('active');
    });

    $('.open-let-talk').on('click', function (e) {

        e.preventDefault();
        $('#let-talk').addClass('active');
    });
    $('.popup__close').on('click', function (e) {

        e.preventDefault();
        $('.popup').removeClass('active');
    });


    $('.header__nav').on('click', '._close', function (e) {

        e.preventDefault();
        $(e.delegateTarget).removeClass('active');
    });

    $('.js-mh').matchHeight();

    scrollToTop.init({
        debug: true
    });

});

